import { createSlice } from "@reduxjs/toolkit";
import { getServices } from "../services/api";
import { ResponseApi } from "../models/response";


interface initialStateProps {
    isLoading: boolean;
    status: string;
    values: ResponseApi | null;
}

const initialState: initialStateProps = {
    isLoading: false, // en cours de récupération
    status: "",// status de l'api
    values: null// les valeurs envoyé par l'api
};

export const serviceSlice = createSlice({
    name: "service",
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // la requette est en cours d'execution
        [getServices.pending.type]: (state,action) => {
            state.isLoading = true;
            state.status = "pending";
        },

        // la requette asynchrone est terminé
        [getServices.fulfilled.type]: (state,{payload}) => {
            state.isLoading = false;
            state.status = "success";
            state.values = payload; 
        },

        // la requette à échoiué ou renvoie une erreur
        [getServices.rejected.type]: (state,action) => {
            state.isLoading = false;
            state.status = "failed";
        }
    },
});

export default serviceSlice.reducer;
/**
 * Lorsque vous utilisez Redux Toolkit avec les thunk async actions, 
 * il génère automatiquement trois types d'actions pour chaque fonction asynchrone : "pending", "fulfilled" et "rejected". 
 * Ces types d'actions sont utilisés pour indiquer l'état de la requête asynchrone.
 * 
 * "pending" : L'action "pending" est envoyée lorsque la requête asynchrone est en cours d'exécution.
 * "fulfilled" : L'action "fulfilled" est envoyée lorsque la requête asynchrone est terminée avec succès et que les données sont disponibles.
 * "rejected" : L'action "rejected" est envoyée si la requête asynchrone échoue ou renvoie une erreur.
 */