import { createAsyncThunk } from "@reduxjs/toolkit"
import http from "./http-common";

// liste des services
export const getServices = createAsyncThunk("services/getServices", async () => {
    try {
        const response = await http.get("/own/sub-categories")
        return response.data
    } catch (error) {
        console.log(error)
    }
})

// liste des pays
export const getCountries = createAsyncThunk("countries/getCountries", async () => {
    try {
        const response = await http.get("/own/countries")
        return response.data
    } catch (error) {
        console.log(error)
    }
})