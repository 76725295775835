import { createSlice } from "@reduxjs/toolkit";
import { getCountries } from "../services/api";
import { ResponseApi } from "../models/response";


interface initialStateProps {
    isLoading: boolean;
    status: string;
    values: ResponseApi | null;
}

const initialState: initialStateProps = {
    isLoading: false, // en cours de récupération
    status: "",// status de l'api
    values: null// les valeurs envoyé par l'api
};

export const countrySlice = createSlice({
    name: "country",
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // la requette est en cours d'execution
        [getCountries.pending.type]: (state,action) => {
            state.isLoading = true;
            state.status = "pending";
        },

        // la requette asynchrone est terminé
        [getCountries.fulfilled.type]: (state,{payload}) => {
            state.isLoading = false;
            state.status = "success";
            state.values = payload; 
        },

        // la requette à échoiué ou renvoie une erreur
        [getCountries.rejected.type]: (state,action) => {
            state.isLoading = false;
            state.status = "failed";
        }
    },
});

export default countrySlice.reducer;