


import { useAppDispatch } from "../../../utils/store"; 
import { ChevronLeftIcon} from "@heroicons/react/24/solid";
import { useHistory } from "react-router-dom";
import { Alerts, ProgressBar } from "../../components";
import { initialize } from "../../../slices/progress-bar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { countryList, userInfo } from "../../../slices/selectors/selectors";
import { getCountries } from "../../../services/api";
import { setCountryId, setFirstName, setLastName, setPassword, setPhoneNumber } from "../../../slices/register"; 

const AccountPage: React.FC = () => {

    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>("");
    const countriesList = useSelector(countryList);
    const registerInfo = useSelector(userInfo);
    const navigate = useHistory();

    useEffect(() => { 
        dispatch(getCountries());
        dispatch(initialize(50*1))
    }, [dispatch]);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const target = event.currentTarget; 

        if(target.first_name.value === '' || target.last_name === '' || target.phone_number.date || target.password.value === ''
            ){
            setError("Tous les champs sont obligatoires");
            return;
        }else{
            dispatch(setFirstName(target.first_name.value));
            dispatch(setLastName(target.last_name.value)); 
            dispatch(setPassword(target.password.value));
            dispatch(setCountryId(target.country.value));
            dispatch(setPhoneNumber(target.phone_number.value)); 

            //dispatch(setBirthDay(target.birth_day.value));
            //dispatch(setBirthMonth(target.birth_month.value));

            navigate.push('/society');
        }
    }

    const onBack = () => { 
        navigate.push('/');
    }

    return (
        <div className="form-screen">
            <div className="form-panel">
                <div className="flex items-center justify-between">
                    <button className="form-btn-back" type="button" onClick={onBack}>
                        <ChevronLeftIcon className="w-4 h-4" />
                        <span>Retour</span>
                    </button>
                    <img src="https://youaps.com/assets/images/uapps-logo-dark.png" className="w-32" alt="" />
                </div>
                
                <form className=" space-y-5" onSubmit={onSubmit}>
                    <div className="space-y-1">
                        <h3 className="form-title pt-2">Identifiez-vous.</h3> 
                    </div>

                    <div className="space-y-4 py-2"> 
                        {error!=="" ? <Alerts name={error}/> : null}

                        <div>
                            <label htmlFor='last_name' className='ip-label'>Nom</label>
                            <input className='ip-field' id="last_name" name="last_name" defaultValue={registerInfo.last_name} type='text' placeholder='Indiquez votre nom de famile' />
                        </div>

                        <div>
                            <label htmlFor='first_name' className='ip-label'>Prénom</label>
                            <input className='ip-field' id="first_name" type='text' defaultValue={registerInfo.first_name} placeholder='Indiquez votre prénom' />
                        </div>

                        {/*<div className="grid grid-cols-3 gap-x-3">
                            <div>
                                <label htmlFor="birth_day" className="ip-label">Jour</label>
                                <select className="ip-field" id="birth_day" name="birth_day" defaultValue={registerInfo.birth_day}>
                                    {daysData.map((day: any) => (
                                        <option value={String(day.id)}>{day.id}</option>
                                    ))}
                                </select>
                            </div> 
                            <div className=" col-span-2"> 
                                <label htmlFor="birth_month" className="ip-label">Mois - Année</label>
                                <input className='ip-field' id="birth_month" defaultValue={registerInfo.birth_month} name="birth_month" type='month' />
                            </div>
                        </div>*/}

                        <div>
                            <label htmlFor='password' className='ip-label'>Mot de passe</label>
                            <input className='ip-field' id="password" defaultValue={registerInfo.password} name="password" type='text' placeholder='Indiquez un mot de passe' />
                        </div>

                        <div>
                            <label htmlFor='phone_number' className='ip-label'>Numéro de téléphone</label> 
                            <div className='flex'>
                                <select className='text-sm font-medium px-2 border rounded-l' name="country" defaultValue={registerInfo.country_id}>
                                    {countriesList?.data.map((country: any) => ( 
                                        <option key={country.id} value={country.id}>+{country.indicatif}</option>
                                    ))}
                                </select>   
                                <input type='tel' id="phone_number" name="phone_number" defaultValue={registerInfo.phone_number} className='ip-field-select' placeholder="indiquer votre pays et numéro" />
                            </div>
                        </div>
                    </div>
                    
                    <div>
                        <button className="form-btn" type="submit">
                            <span>Continuer</span> 
                        </button>
                    </div>

                    <div>
                        <ProgressBar />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AccountPage;