import { RootState } from "../../utils/store";


// liste des services
export const isLoadingServiceList = (state: RootState) => state.service.isLoading;
export const servicesList = (state: RootState) => state.service.values; 
export const progressValue = (state: RootState) => state.progressBar.progressValue;
export const countryList = (state: RootState) => state.country.values;


// information d'enrégistrement
export const userInfo  = (state: RootState) => state.register.user;