import { createSlice } from "@reduxjs/toolkit"; 


interface initialStateProps {
    progressValue: number; 
}
 
const initialState: initialStateProps = {
    progressValue: 0
};

export const progressBarSlice = createSlice({
    name: "service",
    initialState: initialState,
    reducers: {
        initialize: (state,action) => {
            state.progressValue = action.payload; 
            //localStorage.setItem('progressBar', `${state.progressValue}`);
        },
        increment: (state) => {
            state.progressValue += (100/8);
            //localStorage.setItem('progressBar', `${state.progressValue}`);
        },
        decrease: (state) => {   
            state.progressValue -= (100/8);
            //localStorage.setItem('progressBar', `${state.progressValue}`);
        }
    },
    extraReducers: { 
    },
});

export const {initialize,increment,decrease} = progressBarSlice.actions;

export default progressBarSlice.reducer;