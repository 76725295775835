import React from 'react';
import {
  BrowserRouter as Router, 
  Route,
  Switch,
} from "react-router-dom";   
import EmailPage from '../features/pages/register/email'; 
import AccountPage from '../features/pages/register/account';
import SocietyPage from '../features/pages/register/society';
/*import ChoiceServicePage from '../features/pages/register/choice-service';
import EmailPage from '../features/pages/register/email';
import AccountPage from '../features/pages/register/account';
import SocietyPage from '../features/pages/register/society';
import AddressPage from '../features/pages/register/address';
import SoftwareUsedPage from '../features/pages/register/software-used';
import HeartAboutYouPage from '../features/pages/register/heart-about-you';
import PricingPage from '../features/pages/register/pricing';
import SuccessPage from '../features/pages/register/success';
export const YouRoutes = createBrowserRouter([

  {path: "/", element:<EmailPage />},
  {path: "/account", element:<AccountPage />},
  {path: "/society", element:<SocietyPage />},
  {path: "/service", element:<ChoiceServicePage />},
  {path: "/address", element:<AddressPage />},
  {path: "/software-used", element:<SoftwareUsedPage />},
  {path: "/heart-about-you", element:<HeartAboutYouPage />},
  {path: "/pricing", element:<PricingPage />},
  {path: "/success", element:<SuccessPage />}, 
    
  
]);*/

export default function YouRoutes() {
  return (
    <Router> 
      <Switch>
        <Route exact path="/" component={EmailPage} />
        <Route path="/account" component={AccountPage} />
        <Route path="/society" component={SocietyPage} />
        {/*<Route path="/service" component={ChoiceServicePage} />
        <Route path="/address" component={AddressPage} />
        <Route path="/software-used" component={SoftwareUsedPage} />
        <Route path="/heart-about-you" component={HeartAboutYouPage} />
        <Route path="/pricing" component={PricingPage} />
        <Route path="/success" component={SuccessPage} />*/}
      </Switch>
    </Router>
  )
}

 

