import { createSlice } from "@reduxjs/toolkit"; 


interface initialStateProps {
    user: any;
}
 
const initialState: initialStateProps = { 
    user: {
        email: "",
        accet_terms: null,

        first_name: "",
        last_name: "", 
        password: "",
        country_id: null,
        phone_number: "",

        /*birth_day: null,
        birth_month: null,
        birth_year: null,*/

        society_name: "",
        website_url: "",
        user_team: null,

        categories: [],
        //softwareUsed: [],
        //otherSoftwareUsed: "",

        address: "",

        //methodUsed: [],
        //otherMethodUsed: "",

        not_building: null,
    }
};

export const registerSlice = createSlice({
    name: "register",
    initialState: initialState,
    reducers: {
        setEmail: (state,action) => { state.user.email = action.payload},
        setAcceptTerms: (state,action) => {state.user.accet_terms = action.payload},

        setFirstName: (state,action) => { state.user.first_name = action.payload},
        setLastName: (state,action) => { state.user.last_name = action.payload}, 
        setPassword: (state,action) => { state.user.password = action.payload},
        setCountryId: (state,action) => { state.user.country_id = action.payload},
        setPhoneNumber: (state,action) => { state.user.phone_number = action.payload},

        /*setBirthDay: (state,action) => { state.user.birth_day = action.payload},
        setBirthMonth: (state,action) => { state.user.birth_month = action.payload},
        setBirthYear: (state,action) => { state.user.birth_year = action.payload},*/

        setSocietyName: (state,action) => { state.user.society_name = action.payload},
        setWebsiteUrl: (state,action) => { state.user.website_url = action.payload},
        setUserTeam: (state,action) => { state.user.user_team = action.payload},

        setCategories: (state,action) => {state.user.categories = action.payload},
        /*setSoftwareUsed: (state,action) => {state.user.softwareUsed = action.payload},
        setOtherSoftwareUsed: (state,action) => {state.user.otherSoftwareUsed = action.payload},*/

        setAddress: (state,action) => { state.user.address = action.payload},

        /*setMethodUsed: (state,action) => { state.user.methodUsed = action.payload},
        setOtherMethodUsed: (state,action) => { state.user.otherMethodUsed = action.payload},*/

        setNotBuilding: (state,action) => { state.user.not_building = action.payload},
    },
    extraReducers: { 
    },
});

export const {
    setEmail,
    setAcceptTerms,

    setFirstName,
    setLastName, 
    setPassword,
    setCountryId,
    setPhoneNumber,

    /*setBirthDay,
    setBirthMonth,
    setBirthYear,*/

    setSocietyName,
    setWebsiteUrl,
    setUserTeam,

    setCategories,
    /*setSoftwareUsed,
    setOtherSoftwareUsed,*/

    setAddress,

    /*setMethodUsed,
    setOtherMethodUsed,*/

    setNotBuilding,
} = registerSlice.actions;

export default registerSlice.reducer;