


import { useAppDispatch } from "../../../utils/store"; 
import { ChevronLeftIcon} from "@heroicons/react/24/solid";
import { useHistory } from "react-router-dom";
import { Alerts, ProgressBar } from "../../components";
import { initialize } from "../../../slices/progress-bar";
import { useEffect, useState } from "react"; 
import { getCountries } from "../../../services/api";
import { useSelector } from "react-redux";
import { userInfo } from "../../../slices/selectors/selectors";
import { setSocietyName, setUserTeam, setWebsiteUrl } from "../../../slices/register";



const SocietyPage: React.FC = () => {

    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>("");
    const registerInfo = useSelector(userInfo);
    const navigate = useHistory();

    useEffect(() => { 
        dispatch(getCountries());
        dispatch(initialize(100));
    }, [dispatch]);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const target = event.currentTarget;
        if(target.society_name.value === ''){
            setError("Le nom de votre sociéte est obligatoire");
            return;
        }else{
            dispatch(setSocietyName(target.society_name.value));
            dispatch(setWebsiteUrl(target.website_url.value));
            dispatch(setUserTeam(target.people.value));

            navigate.push('/service');
        }
    }

    const onBack = () => {
        navigate.push('/account');
    }

    return (
        <div className="form-screen">
            <div className="form-panel">
                <div className="flex items-center justify-between">
                    <button className="form-btn-back" type="button" onClick={onBack}>
                        <ChevronLeftIcon className="w-4 h-4" />
                        <span>Retour</span>
                    </button>
                    <img src="https://youaps.com/assets/images/uapps-logo-dark.png" className="w-32" alt="" />
                </div>
                
                <form className=" space-y-5" onSubmit={onSubmit}>
                    <div className="space-y-1">
                        <h3 className="form-title pt-3">Faites connaitre votre entreprise.</h3>
                        {/*<p className="form-subtitle">Ceci est le nom que vos clients verront. Votre dénomination juridique ainsi que celle utilisée pour la facturation pourront être ajoutées ultérieurement.</p>*/}
                    </div>

                    <div className="space-y-4 py-2"> 
                        {error!=="" ? <Alerts name={error}/> : null}

                        <div>
                            <label htmlFor='society_name' className='ip-label'>Nom de l’entreprise</label>
                            <input className='ip-field' id="society_name" name="society_name" type='text' defaultValue={registerInfo.society_name} placeholder='ex: kacyeJones Beauty' />
                        </div>

                        <div>
                            <label htmlFor='website_url' className='ip-label'>Site web <span className='text-gray-400 font-light'>(facultatif)</span></label>
                            <input className='ip-field' id="website_url" type='text' defaultValue={registerInfo.website_url} name="website_url" placeholder='ex: siteweb.com' />
                        </div>
                         
                        <div>
                            <label htmlFor='people'  className='ip-label'>Effectif des employés</label>
                            <select className="ip-field" defaultValue={registerInfo.user_team} name="people">
                                <option value="0">Moi uniquement</option>
                                <option value="1">2 - 5 personnes</option>
                                <option value="2">6 - 10 personnes</option>
                                <option value="3">+ 11 personnes</option>
                            </select>
                        </div>

                        <div>
                            <label className='ip-label'>Adresse de votre entreprise</label>
                            <div className="flex items-start mb-4">
                                <input id="accept-terms" type="checkbox" defaultChecked={registerInfo.accept_terms} name="accept_terms" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                                <label htmlFor="accept-terms" className="ml-3 text-sm font-medium">Je n’ai pas d’établissement</label>
                            </div>
                        </div>
                    </div>
                    
                    <div>
                        <button className="form-btn" type="submit">
                            <span>Continuer</span> 
                        </button>
                    </div>

                    <div>
                        <ProgressBar />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SocietyPage;