import { useSelector } from "react-redux";
import { progressValue } from "../../../slices/selectors/selectors";


const ProgressBar: React.FC = () => {

    const progress = useSelector(progressValue);

    return (
        <div className="w-full bg-gray-200 rounded-full h-1">
            <div className="bg-gray-600 h-1 rounded-full transition-all ease-out duration-200" style={{width: `${progress}%`}} />
        </div>
    )
}

export { ProgressBar };