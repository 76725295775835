import { initReactI18next } from "react-i18next";
import i18nBackend from "i18next-http-backend";
import i18next from "i18next";

const getHostUrl = process.env.DEV_MODE === "developpement" ? "http://localhost:3000" : "";

i18next
    .use(i18nBackend)
    .use(initReactI18next).init({
        lng: "fr",
        fallbackLng: 'fr',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: `${getHostUrl}/translations/{{lng}}.json`
        }
    }
);

export default i18next;