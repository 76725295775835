


import { useAppDispatch } from "../../../utils/store";
import { useSelector } from "react-redux";  
import { Alerts } from "../../components";
import { initialize } from "../../../slices/progress-bar";
import { useEffect, useState } from "react";
import { setAcceptTerms, setEmail } from "../../../slices/register";
import { userInfo } from "../../../slices/selectors/selectors";
import { Link, useHistory } from "react-router-dom";



const EmailPage: React.FC = () => {

    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>("");
    const registerInfo = useSelector(userInfo);
    const navigate = useHistory();

    useEffect(() => { 
        dispatch(initialize(0));
    }, [dispatch]);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const target = event.currentTarget;

        if(target.email.value===""){
            setError("Adresse email invalide");
            return;
        }

        if(target.accept_terms.checked){
            // enrégistrer des info
            dispatch(setEmail(target.email.value))
            dispatch(setAcceptTerms(target.accept_terms.checked))
            navigate.push('/account')
        }else {
            setError("Veuillez accepté les conditions d'utilisations de l'application"); 
        }
        return;
    }

    return (
        <div className="form-screen">
            <div className="form-panel">
                <img src="https://youaps.com/assets/images/uapps-logo-dark.png" className="w-32" alt="" />
                
                <form className="space-y-5" onSubmit={onSubmit}>
                    <div className="space-y-1">
                        <h3 className="form-title">Gérez et développez votre business</h3> 
                    </div>

                    <div className="space-y-4 py-2"> 
                        {error!=="" ? <Alerts name={error}/> : null}

                        <div>
                            <label className="ip-label" htmlFor="email">Entrez votre adresse email</label>
                            <input type="email" name="email" className="ip-field" id="email" defaultValue={registerInfo.email} placeholder="exemple@gmail.com"/>
                        </div>

                        <div className="flex items-start mb-4">
                            <input id="accept-terms" type="checkbox" defaultChecked={registerInfo.accept_terms} name="accept_terms" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                            <label htmlFor="accept-terms" className="ml-3 text-sm font-medium">
                                J'accepte la <Link to="/" className='text-blue-600'>Politique de confidentialité, </Link> les
                                <Link to="/" className='text-blue-600'>Conditions d'utilisation</Link> et les 
                                <Link to="/" className='text-blue-600'>Conditions générales de vente.</Link>
                            </label>
                        </div>
                    </div>
                    
                    <div>
                        <button className="form-btn" type="submit">
                            <span>Continuer</span>
                        </button>
                    </div>

                    
                </form>
            </div>
        </div>
    )
}

export default EmailPage;